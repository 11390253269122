import React from 'react';
import ErrorBoundary from './components/ErrorBoundary';
import { LoadingProvider } from './components/Legacy/Commons/hooks/loading';
import i18next from 'i18next';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { init } from './components/Legacy/translations';
import { ModalProvider } from 'styled-react-modal';
import { SpecialModalBackground } from './components/Legacy/Commons/SpecialModalBackground';
import AppRoutes from './routes/AppRoutes';
import { useLocation } from 'react-router-dom';
import useBackendNotification from './components/Legacy/Commons/hooks/useBackendNotifications';

init();

function App() {
  const { t: tNotifications } = useTranslation('NOTIFICATIONS');
  const location = useLocation();
  useBackendNotification(tNotifications);

  return (
    <I18nextProvider i18n={i18next}>
      <ErrorBoundary key={location.pathname}>
        <ModalProvider backgroundComponent={SpecialModalBackground}>
          <LoadingProvider>
            <AppRoutes />
          </LoadingProvider>
        </ModalProvider>
      </ErrorBoundary>
    </I18nextProvider>
  );
}

export default App;
