import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import 'react-tabs/style/react-tabs.css';
import '@pinv/ui/index.css';
import 'App.css';
import './index.css';
import App from './App';
import { NotificationProvider } from './components/Legacy/Commons/hooks/Notifications';
import { UserLifecycleProvider } from './providers/UserLifecycleProvider';

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <NotificationProvider>
      <BrowserRouter>
        <UserLifecycleProvider>
          <QueryClientProvider client={queryClient}>
            <App />
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </UserLifecycleProvider>
      </BrowserRouter>
    </NotificationProvider>
  </React.StrictMode>,
);
