import React, { useEffect } from 'react'; // eslint-disable-line
import { getNotifications } from '../../../CompanyProfile/api';
import { TOKEN_NAME } from '../../../../../lib/constants';
import { getToken } from '../../../../../lib/token';
import { useNotification } from '../Notifications';
import { useNavigate } from 'react-router-dom';

const useBackendNotification = (t) => {
  const token = getToken(TOKEN_NAME);
  const { showNotification } = useNotification();
  const navigate = useNavigate();

  const getBackendNotifications = async () => {
    if (!token) return;

    const parsedNotifications = await getNotifications(token);
    parsedNotifications.map(({ title, message, actions, autoclose }) =>
      showNotification({
        title: t(title),
        text: t(message),
        type: 'info',
        autoclose,
        buttons: actions.map(({ label, redirect, externalLink = false }) => ({
          label: t(label),
          onClick: () => {
            if (externalLink) {
              window.location.href = redirect;
              return null;
            } else {
              navigate(redirect, { replace: true });
            }
          },
          props: {
            primary: true,
          },
        })),
      }),
    );
  };

  useEffect(() => {
    getBackendNotifications();
  }, [token]);
};

export default useBackendNotification;
